import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { staticClass: "my-3", attrs: { "align-center": "" } },
    [
      _vm.showScheduleOverviewText
        ? _c(
            "div",
            {
              staticClass:
                "Menu-Schedule-Verbiage pa-3 Body-1-Black-High-Emphasis-Left pb-3 noselect"
            },
            [
              _c("b", [_vm._v(_vm._s(_vm.menuSelection))]),
              _vm._v(
                "\n    " +
                  _vm._s("is available ") +
                  "\n    " +
                  _vm._s(_vm.timeDurationPrefix) +
                  "\n    "
              ),
              _vm.timeDuration.length > 0
                ? _c("b", [_vm._v(_vm._s(_vm.timeDuration))])
                : _vm._e(),
              _vm.repeatSelection.length > 0
                ? _c("b", [_vm._v(_vm._s(_vm.repeatSelection))])
                : _vm._e(),
              _vm._v("\n    " + _vm._s(_vm.dateDurationPrefix) + "\n    "),
              _vm.dateDuration.length > 0
                ? _c("b", [_vm._v(_vm._s(_vm.dateDuration))])
                : _vm._e(),
              _vm._v("\n    " + _vm._s(_vm.serviceTypePrefix) + "\n    "),
              _vm.serviceType.length > 0
                ? _c("b", [_vm._v(_vm._s(_vm.serviceType))])
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }