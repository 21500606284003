<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs8>
        <view-title />
      </v-flex>
      <v-flex xs4>
        <v-layout justify-end v-if="isEventEdit && canWriteMenuSchedule">
          <div class="Remove-Menu-Btn mt-4 noselect" v-on:click="deleteEvent()">
            <v-icon color="#ea526f" class="Remove-Icon">mdi-delete-outline</v-icon>Delete Menu Set
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class="mt-1 mb-4 H6-Selected-On-Surface-High-Emphasis-Left">
      The following will serve as your mobile menus
    </v-layout>
    <v-layout row wrap>
      <v-form ref="form" lazy-validation v-model="isFormValid" style="width: 100%">
        <v-card
          class="v-card-custom mb-5"
          width="100%"
          v-for="(menuEvent, index) of menuEvents"
          v-bind:key="index"
        >
          <v-layout>
            <v-flex>
              <div class="H5-Primary-Left">Menu Details</div>
            </v-flex>
            <v-flex xs4 v-if="canWriteMenuSchedule && menuEvents.length > 1">
              <v-layout justify-end>
                <div
                  class="Remove-Menu-Btn mt-4 noselect"
                  v-on:click="removeEvent(menuEvent, index)"
                >
                  <v-icon color="#ea526f" class="Remove-Icon">mdi-delete-outline</v-icon>Delete Menu
                  Set
                </div>
              </v-layout>
            </v-flex>
          </v-layout>
          <div>
            <v-layout class="mt-5">
              <v-flex xs6>
                <v-autocomplete
                  :items="menus"
                  label="Menu"
                  placeholder
                  v-model="menuEvent.menu"
                  :rules="requiredMenu"
                  :disabled="!canWriteMenuSchedule || isEventEdit"
                  item-text="label"
                  item-value="value"
                />
              </v-flex>
            </v-layout>
            <v-layout class="mt-5 H6-Secondary-Left" style="width: 240px"> Menu Duration </v-layout>
            <v-layout>
              <v-flex>
                <v-layout>
                  <v-checkbox
                    v-model="menuEvent.schedule.isAllDay"
                    hide-details
                    color="#4020ae"
                    @click.native="toggleAllDay(menuEvent)"
                    :false-value="false"
                    :true-value="true"
                    label="All day"
                    :disabled="!canWriteMenuSchedule"
                  />
                </v-layout>
                <v-layout>
                  <v-flex xs12>
                    <v-text-field
                      class="Not-Selected-Error"
                      :rules="timeRules(menuEvent)"
                      :value="`${menuEvent.schedule.startTime}-${menuEvent.schedule.endTime}`"
                      :disabled="!canWriteMenuSchedule"
                    />
                  </v-flex>
                </v-layout>
                <v-layout class="mt-3">
                  <v-flex xs3 class="pr-2">
                    <time-select
                      v-if="menuEvent.schedule.renderTime"
                      label="Start Time"
                      v-bind:value.sync="menuEvent.schedule.startTime"
                      :rules="timeOpenRules(menuEvent.schedule.isAllDay)"
                      autocomplete
                      :disabled="menuEvent.schedule.isAllDay || !canWriteMenuSchedule"
                      :type="menuEvent.schedule.isAllDay ? 'text' : 'time'"
                    />
                  </v-flex>
                  <v-flex xs3>
                    <time-select
                      v-if="menuEvent.schedule.renderTime"
                      :label="menuEvent.closeTimeLabel || 'End Time'"
                      v-bind:value.sync="menuEvent.schedule.endTime"
                      :rules="timeClosedRules(menuEvent.schedule.isAllDay)"
                      autocomplete
                      :disabled="menuEvent.schedule.isAllDay || !canWriteMenuSchedule"
                      :type="menuEvent.schedule.isAllDay ? 'text' : 'time'"
                    />
                    <div class="nextDayLabel">{{ menuEvent.nextDayLabel }}</div>
                  </v-flex>
                </v-layout>
                <v-layout class="mt-3">
                  <v-flex xs3>
                    <v-combobox
                      v-if="menuEvent.schedule.renderRecurrence"
                      :items="repeatOptions"
                      label="Repeat"
                      :value="menuEvent.schedule.repeat.type"
                      @input="setRepeatType($event, menuEvent)"
                      :rules="[...requiredField, isValidRecurrence(menuEvent)]"
                      :disabled="!canWriteMenuSchedule"
                    />
                  </v-flex>
                </v-layout>
                <v-layout class="mt-3">
                  <v-checkbox
                    v-model="menuEvent.schedule.hasEndDate"
                    v-if="menuEvent.schedule.repeat.type !== RecurrenceTypes.NeverRepeat"
                    hide-details
                    color="#4020ae"
                    @click.native.stop
                    :false-value="true"
                    :true-value="false"
                    label="No end date"
                    :disabled="!canWriteMenuSchedule"
                  />
                </v-layout>
                <v-layout class="mt-3">
                  <v-flex xs4 class="mr-1">
                    <v-menu
                      ref="startDate"
                      v-model="menuEvent.schedule.showStartDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      z-index="2"
                      lazy
                      transition="scale-transition"
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-text-field
                            :value="formatLocaleDate(menuEvent.schedule.startDate)"
                            readonly
                            class="DateStyle border-label-input"
                            persistent-hint
                            outline
                            label="Start Date"
                            :rules="requiredField"
                            append-icon="mdi-calendar-blank"
                            :disabled="!canWriteMenuSchedule"
                          ></v-text-field>
                        </span>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="menuEvent.schedule.startDate"
                        @input="onDateChange(menuEvent.schedule)"
                        :min="today"
                        :disabled="!canWriteMenuSchedule"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs4 v-if="menuEvent.schedule.hasEndDate">
                    <v-menu
                      ref="endDate"
                      v-model="menuEvent.schedule.showEndDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      z-index="2"
                      transition="scale-transition"
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-text-field
                            :value="formatLocaleDate(menuEvent.schedule.endDate)"
                            readonly
                            class="DateStyle border-label-input"
                            persistent-hint
                            outline
                            label="End Date"
                            :rules="requiredField"
                            append-icon="mdi-calendar-blank"
                            :disabled="!canWriteMenuSchedule"
                          ></v-text-field>
                        </span>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="menuEvent.schedule.endDate"
                        @input="menuEvent.schedule.showEndDate = false"
                        :min="getMinCloseDate(menuEvent.schedule.startDate)"
                        :disabled="!canWriteMenuSchedule"
                      />
                    </v-menu>
                  </v-flex>
                </v-layout>
                <v-layout class="mt-5 H6-Secondary-Left" style="width: 240px">
                  Service Types
                </v-layout>
                <v-layout>
                  <v-flex xs12>
                    <v-text-field
                      class="Not-Selected-Error"
                      :rules="checkboxRules(menuEvent.is.pickup || menuEvent.is.delivery)"
                      :value="(menuEvent.is.delivery ? 1 : 0) + (menuEvent.is.pickup ? 1 : 0)"
                      :disabled="!canWriteMenuSchedule"
                    />
                  </v-flex>
                </v-layout>
                <v-layout style="width: 300px" class="mb-5">
                  <v-flex>
                    <v-checkbox
                      v-model="menuEvent.is.pickup"
                      hide-details
                      color="#4020ae"
                      @click.native.stop
                      :false-value="false"
                      :true-value="true"
                      label="Pickup"
                      :disabled="!isDeliveryEnabled || !isPickupEnabled || !canWriteMenuSchedule"
                    />
                    <div class="noselect typeDisabled" v-if="!isPickupEnabled">
                      (Not available at this brand)
                    </div>
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      v-model="menuEvent.is.delivery"
                      hide-details
                      color="#4020ae"
                      @click.native.stop
                      :false-value="false"
                      :true-value="true"
                      :disabled="!isDeliveryEnabled || !isPickupEnabled || !canWriteMenuSchedule"
                      label="Delivery"
                    />
                    <div class="noselect typeDisabled" v-if="!isDeliveryEnabled">
                      (Not available at this brand)
                    </div>
                  </v-flex>
                </v-layout>
                <sheduleOverviewText :menuEvent="menuEvent" :menus="menus" />
              </v-flex>
            </v-layout>
          </div>
        </v-card>
      </v-form>
    </v-layout>
    <v-dialog persistent v-model="dialog.isOpen" width="580px" height="428px">
      <v-card class="pa-4" v-if="dialog.isOpen">
        <v-form lazy-validation ref="recurrenceForm" v-model="isRecurrenceValid">
          <v-card-title class="H4-Secondary-Center">Custom Recurrence</v-card-title>
          <v-layout
            class="mx-4 my-4 H6-Selected-On-Surface-High-Emphasis-Left"
            style="width: 330px"
          >
            <v-flex> Repeat every </v-flex>
            <v-flex>
              <v-layout style="width: 60px; margin-top: -15px">
                <v-select
                  :items="weeks"
                  :rules="requiredField"
                  v-model="dialog.data.repeat"
                  @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"
                />
              </v-layout>
            </v-flex>
            <v-flex> Week(s) </v-flex>
          </v-layout>
          <v-layout class="mx-4">
            <v-flex>
              <v-layout class="mt-4 H6-Selected-On-Surface-High-Emphasis-Left">
                Repeat on
              </v-layout>
              <v-layout justify-space-between class="my-4" style="width: 250px">
                <v-flex
                  v-for="(day, dIndex) of ['S', 'M', 'T', 'W', 'T', 'F', 'S']"
                  v-bind:key="dIndex"
                  v-on:click="toggleDay(dialog.data.days, dIndex)"
                >
                  <v-layout
                    class="Weekday noselect"
                    align-center
                    justify-center
                    v-bind:class="{ SelectedWeekDay: dialog.data.days[dIndex] }"
                  >
                    {{ day }}
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="blue" v-on:click="cancelRecurrence()">CANCEL</v-btn>
            <v-btn
              flat
              color="blue"
              :disabled="
                !isRecurrenceValid ||
                  validDaysCount(dialog.data.days) === 0 ||
                  !canWriteMenuSchedule
              "
              v-on:click="confirmRecurrence()"
              >CONTINUE</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-layout justify-center>
      <v-flex
        v-if="!isEventEdit && canWriteMenuSchedule"
        class="Subtitle-1-Primary-Left noselect"
        style="cursor: pointer"
        v-on:click="menuEvents.push(getDefaultMenuEvent())"
      >
        <v-icon color="#0d73d8" class="mr-1">mdi-plus</v-icon>Schedule another Menu
      </v-flex>
      <v-flex v-if="isFormDirty && canWriteMenuSchedule">
        <v-layout flex column align-end>
          <v-flex>
            <v-btn flat color="rounded m-2" @click="cancel()" :disabled="isSaving">Cancel</v-btn>
            <v-btn color="primary rounded m-2" @click="save()" :disabled="!isFormValid || isSaving">
              <v-icon class="mr-3">mdi-content-save</v-icon>
              Save
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import DateTime from 'luxon/src/datetime.js';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import timeSelect from '@/components/timeSelect';
import rules from '@/rules';
import { CalendarTypes, RecurrenceTypes, UserRoles } from '@/constants';
import sheduleOverviewText from './scheduleOverviewText';

export default {
  props: [],
  components: {
    timeSelect,
    sheduleOverviewText,
  },
  data: () => ({
    isSaving: false,
    isFormValid: true,
    isRecurrenceValid: true,
    RecurrenceTypes,
    repeatOptions: [
      RecurrenceTypes.Daily,
      RecurrenceTypes.Weekday,
      RecurrenceTypes.NeverRepeat,
      RecurrenceTypes.Custom,
    ],
    dialog: {
      isOpen: false,
      data: {},
      backup: {},
    },
    menus: [],
    menuEvents: [],
    scheduledEvents: [],
    weeks: [],
    daysLabel: ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'],
    recurrenceRules: [rules.required('Recurrence is required'), rules.min(1, 'Invalid')],
    requiredField: [rules.required('Required')],
    requiredMenu: [rules.required('Menu is required')],
    timeClosedRules: (unlessCondition) => [
      rules.requiredUnless('Close time is required', unlessCondition),
    ],
    timeOpenRules: (unlessCondition) => [
      rules.requiredUnless('Start time is required', unlessCondition),
    ],
    checkboxRules: (d) => [() => !!d || 'Please select at least one service type'],
    menuEventsBackup: null,
    defaultMenuEvent: {
      menu: null,
      schedule: {
        hasEndDate: true,
        startTime: null,
        endTime: null,
        showStartDate: false,
        showEndDate: false,
        startDate: null,
        endDate: null,
        renderTime: true,
        renderRecurrence: true,
        repeat: {
          frequency: null,
          type: null,
          days: null,
        },
        isAllDay: false,
      },
      is: {
        delivery: false,
        pickup: false,
        frictionless: false,
      },
    },
    isEventEdit: false,
    isDeliveryEnabled: false,
    isPickupEnabled: false,
  }),
  computed: {
    ...mapState('sites', ['active_brand', 'active_site']),
    ...mapState('users', ['customPermissions']),
    ...mapGetters('calendar', {
      getEvents: 'getEvents',
    }),
    ...mapGetters('adminPanel', ['userHasAnyRole', 'hasSpecificPermissions']),
    ...mapGetters('centricOSMenu', ['isCentricOSIntegrated']),
    today() {
      return DateTime.local().toISODate();
    },
    isFormDirty() {
      return !isEqual(this.menuEventsBackup, this.menuEvents);
    },
    canWriteMenuSchedule() {
      const hasHighPermissionRole = this.userHasAnyRole([UserRoles.admin, UserRoles.sys_admin]);
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.allowSchedule,
      ]);
      return hasHighPermissionRole || hasSpecificPermission;
    },
  },
  methods: {
    ...mapActions('calendar', { saveEvents: 'saveEvents' }),
    ...mapActions('menus', ['fetchCompanyMenu']),
    ...mapActions('centricOSMenu', ['fetchLocalBrandV2Menus']),

    getDefaultMenuEvent() {
      const defaultMenuEvent = cloneDeep(this.defaultMenuEvent);
      if (!this.isDeliveryEnabled) defaultMenuEvent.is.pickup = true;
      if (!this.isPickupEnabled) defaultMenuEvent.is.delivery = true;
      return defaultMenuEvent;
    },
    getDuration(openTimeIn, closeTimeIn, m) {
      if (!openTimeIn || !closeTimeIn) return 0;
      const today = DateTime.local();
      const startTimes = openTimeIn.split(':');
      const closeTime = closeTimeIn.split(':');

      let startDateTime = DateTime.local();
      let closeDateTime = DateTime.local();

      if (startTimes.length === 1) {
        startDateTime = today.set({ hour: startTimes[0], minutes: '0' });
      } else {
        startDateTime = today.set({ hour: startTimes[0], minute: startTimes[1] });
      }

      if (closeTime.length === 1) {
        closeDateTime = today.set({ hour: closeTime[0], minutes: '0' });
      } else {
        closeDateTime = today.set({ hour: closeTime[0], minute: closeTime[1] });
      }
      let duration = closeDateTime.diff(startDateTime, 'minutes');
      let minutes_diff = duration.values.minutes;
      if (m) {
        m.closeTimeLabel = 'End Time';
        m.nextDayLabel = '';
      }

      if (minutes_diff <= 0) {
        const nextDaycloseDateTime = closeDateTime.plus({ days: 1 });
        duration = nextDaycloseDateTime.diff(startDateTime, 'minutes');
        minutes_diff = duration.values.minutes;
        if (m) {
          m.closeTimeLabel = 'End Time (Next Day)';
          m.nextDayLabel = '(Next Day)';
        }
      }

      return minutes_diff;
    },
    timeRules(m) {
      const { startTime, endTime, isAllDay } = m.schedule;
      const isValidTimeRange =
        isAllDay || !startTime || !endTime || this.getDuration(startTime, endTime, m) > 0;
      return [() => isValidTimeRange || 'Plese select a valid time range'];
    },
    convertMenuEventToEvent(menuEvent) {
      // map schedule
      const { repeat, hasEndDate, endDate, startTime, endTime, isAllDay } = menuEvent.schedule;
      const dateStart = DateTime.fromFormat(
        `${menuEvent.schedule.startDate} 00:00`,
        'yyyy-LL-dd HH:mm',
        { zone: `${this.active_brand.timezone}` },
      );
      const { NeverRepeat, Daily, Weekday } = RecurrenceTypes;
      const schedule = {};

      if (!isAllDay) {
        const duration = this.getDuration(startTime, endTime);
        schedule.durationUnit = 'minutes';
        schedule.duration = duration;
        schedule.times = [startTime];
      } else {
        schedule.duration = 1;
      }

      schedule.start = dateStart.toMillis();
      if (hasEndDate && endDate) {
        const dateEnd = DateTime.fromFormat(
          `${menuEvent.schedule.endDate} 00:00`,
          'yyyy-LL-dd HH:mm',
          { zone: `${this.active_brand.timezone}` },
        );
        schedule.end = dateEnd.toMillis();
      }

      switch (menuEvent.schedule.repeat.type) {
        case NeverRepeat:
          schedule.dayOfMonth = [dateStart.day];
          schedule.month = [dateStart.month - 1]; // we use moment in the calendar provider
          schedule.year = [dateStart.year];
          break;
        case Daily:
          break;
        case Weekday:
          schedule.dayOfWeek = [1, 2, 3, 4, 5];
          break;
        default:
          if (!repeat) break;
          schedule.dayOfWeek = Object.keys(repeat.days)
            .filter((d) => repeat.days[d])
            .map((d) => Number(d));
          schedule.every = { week: repeat.frequency };
          break;
      }

      // map data
      const data = {
        color: '#ff8c84',
        calendar: CalendarTypes.menu,
        is: { ...menuEvent.is },
        id: this.$route.params.event_id,
      };
      const menu = this.menus.find((m) => m.value === menuEvent.menu);
      data.title = menu.label || '';
      data.menu = menu.value;
      return { data, schedule };
    },
    convertEventToMenuEvent(event) {
      const { data, schedule } = event;

      const menuEvent = {
        menu: data.menu,
        schedule: {
          hasEndDate: !!schedule.end,
          startTime: (schedule.times && schedule.times[0]) || null,
          isAllDay: !schedule.durationUnit,
          repeat: {},
        },
        is: data.is,
      };

      const { repeat } = menuEvent.schedule;
      if (schedule.every && schedule.every.week) repeat.frequency = schedule.every.week;
      if (repeat.frequency) {
        repeat.days = {};
        schedule.dayOfWeek.forEach((d) => {
          repeat.days[d] = true;
        });
      }
      // this is to determine the repeat type
      const { year, month, dayOfMonth, dayOfWeek } = schedule;
      if (repeat.frequency)
        repeat.type = this.getRepeatSelectionLabel({ days: repeat.days, repeat: repeat.frequency });
      else if (year && month && dayOfMonth) repeat.type = RecurrenceTypes.NeverRepeat;
      else if (isEqual(dayOfWeek, [1, 2, 3, 4, 5])) repeat.type = RecurrenceTypes.Weekday;
      else repeat.type = RecurrenceTypes.Daily;

      if (!menuEvent.schedule.isAllDay) {
        menuEvent.schedule.endTime = this.getStartAndEndTimes(schedule);
      }

      if (schedule.start) {
        menuEvent.schedule.startDate = DateTime.fromMillis(schedule.start)
          .setZone(this.active_brand.timezone)
          .toISODate();
      }
      if (schedule.end) {
        menuEvent.schedule.endDate = DateTime.fromMillis(schedule.end)
          .setZone(this.active_brand.timezone)
          .toISODate();
      }

      const newEvent = merge({}, this.getDefaultMenuEvent(), menuEvent);

      return newEvent;
    },
    getStartAndEndTimes(schedule) {
      if (!schedule && !schedule.times && !schedule.duration) return '';

      const today = DateTime.local();
      const startTime = schedule.times[0];
      const startTimes = startTime.split(':');

      let startDate;

      if (startTimes.length === 1) {
        startDate = today.set({ hour: startTimes[0], minute: '00' });
      } else {
        startDate = today.set({ hour: startTimes[0], minute: startTimes[1] });
      }

      const endDate = startDate.plus({ minutes: schedule.duration });

      return endDate.toFormat('HH:mm');
    },
    formatLocaleDate(date) {
      return !date ? date : DateTime.fromISO(date || this.today).toLocaleString(DateTime.DATE_HUGE);
    },
    getMinCloseDate(date) {
      return DateTime.fromISO(date || this.today)
        .plus({ days: 1 })
        .toISODate();
    },
    onDateChange(menuEvent) {
      menuEvent.showStartDate = false;
      const endDate = DateTime.fromISO(menuEvent.startDate).plus({ days: 1 });
      if (endDate > DateTime.fromISO(menuEvent.endDate)) {
        menuEvent.endDate = endDate.toISODate();
      }
    },
    removeEvent(index) {
      this.menuEvents.splice(index, 1);
    },
    async deleteEvent() {
      const eventId = this.$route.params.event_id;
      const menuEvents = this.getEvents(CalendarTypes.menu).filter(
        (e) => !(e.data && e.data.id === eventId),
      );
      const input = await this.$confirm({
        title: 'Remove Scheduled Menu?',
        message: 'Are you sure you want to remove the schedule for this menu?',
        buttonTrueText: 'REMOVE',
        buttonFalseText: 'CANCEL',
      });
      if (!input) {
        return;
      }
      await this.saveEvents({ events: menuEvents, type: CalendarTypes.menu })
        .then(() => {
          this.$toast('Menu Schedule deleted!');
          this.$store.commit('adminPanel/setViewTitle', {
            depth: 2,
            title: this.active_brand.name,
            to: {
              name: 'brand-overview-menus',
              params: { brand_id: this.active_brand.id, site_id: this.active_site.id },
            },
          });

          this.$router.push({
            name: 'brand-overview-menus',
            params: { site_id: this.active_site.id, brand_id: this.active_brand.id },
          });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Error deleting Menu Schedule');
        });
    },
    async save() {
      const v = this.$refs.form.validate();
      if (!this.isFormValid || !v) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      this.isSaving = true;

      // convert then save
      const menuEvents = [
        ...this.menuEvents.map((m) => this.convertMenuEventToEvent(m)),
        ...this.getEvents(CalendarTypes.menu).filter(
          (e) => e.data.id !== this.$route.params.event_id,
        ),
      ];

      await this.saveEvents({ events: menuEvents, type: CalendarTypes.menu })
        .then(() => {
          this.$toast('Menu Schedule(s) saved!');
          this.$store.commit('adminPanel/setViewTitle', {
            depth: 2,
            title: this.active_brand.name,
            to: {
              name: 'brand-overview-menus',
              params: { brand_id: this.active_brand.id, site_id: this.active_site.id },
            },
          });

          this.$router.push({
            name: 'brand-overview-menus',
            params: { site_id: this.active_site.id, brand_id: this.active_brand.id },
          });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Error saving Menu Schedule(s)');
        });
      this.isSaving = false;
    },
    cancel() {
      this.$set(this, 'menuEvents', cloneDeep(this.menuEventsBackup));
      this.$refs.form.resetValidation();
      this.$toast('All your changes have been reset');
    },
    setRepeatType(type, menuEvent) {
      if (type === RecurrenceTypes.Custom) {
        this.dialog.isOpen = true;
        this.$set(this.dialog, 'data', {
          days: cloneDeep(menuEvent.schedule.repeat.days) || {},
          repeat: cloneDeep(menuEvent.schedule.repeat.frequency) || 1,
        });
        this.$set(this.dialog, 'backup', menuEvent);
      } else {
        menuEvent.schedule.repeat.type = type;
        menuEvent.schedule.repeat.days = null;
        menuEvent.schedule.frequency = null;
        if (type === RecurrenceTypes.NeverRepeat) {
          menuEvent.schedule.hasEndDate = false;
          menuEvent.schedule.startDate = menuEvent.schedule.startDate || this.today;
          menuEvent.schedule.endDate = null;
        }
      }
    },
    cancelRecurrence() {
      this.dialog.isOpen = false;
      this.$set(this.dialog, 'data', {});
      const repeatBackup = this.dialog.backup.schedule.repeat;
      const menuEvent = this.dialog.backup;
      this.$set(menuEvent.schedule, 'repeat', repeatBackup);
      this.$set(this.dialog, 'backup', {});
      menuEvent.schedule.renderRecurrence = false;
      this.$nextTick(() => {
        menuEvent.schedule.renderRecurrence = true;
      });
    },
    confirmRecurrence() {
      if (!this.$refs.recurrenceForm.validate()) return;

      this.$set(this.dialog.backup.schedule, 'repeat', {
        type: this.getRepeatSelectionLabel(this.dialog.data),
        frequency: this.dialog.data.repeat,
        days: this.dialog.data.days,
      });

      this.dialog.isOpen = false;
      this.$set(this.dialog, 'data', {});
      this.$set(this.dialog, 'backup', {});
    },
    getRepeatSelectionLabel(data, differentiateLast = true, startWithUpperCase = true) {
      const { days, repeat } = data;
      let prefix = '';
      if (repeat === 1) prefix += `${startWithUpperCase ? 'W' : 'w'}eekly on`;
      else prefix += `${startWithUpperCase ? 'E' : 'e'}very ${repeat} weeks on`;

      const selectedDays = Object.keys(days)
        .filter((d) => !!days[d])
        .map((d) => `${this.daysLabel[d]}`);
      let label = `${prefix} `;
      const numDays = selectedDays.length;
      if (numDays > 1) {
        selectedDays.forEach((d, i) => {
          if (differentiateLast) {
            if (i < numDays - 2) {
              label += `${d}, `;
            } else if (i === numDays - 2) {
              label += `${d} and `;
            } else {
              label += `${d}`;
            }
          } else if (i < numDays - 1) label += `${d}, `;
          else label += `${d}`;
        });
      } else label += `${selectedDays.join('')}`;
      return label;
    },
    toggleDay(days, index) {
      this.$set(days, index, !days[index]);
    },
    validDaysCount(days) {
      return Object.keys(days).filter((d) => !!days[d]).length;
    },
    isValidRecurrence(menuEvent) {
      return (v) => {
        const { repeat } = menuEvent.schedule;
        const type = (v && v.trim()) || '';
        if (this.repeatOptions.some((r) => r === type)) {
          return true;
        }

        // custom recurrence, check if valid
        const recurrenceLabel = this.getRepeatSelectionLabel({
          repeat: repeat.frequency || '',
          days: repeat.days || {},
        });
        return recurrenceLabel === type || 'Invalid Recurrence';
      };
    },
    toggleAllDay(menuEvent) {
      if (menuEvent.schedule.isAllDay) {
        menuEvent.schedule.startTime = null;
        menuEvent.schedule.endTime = null;
        menuEvent.schedule.renderTime = false;
        this.$nextTick(() => {
          menuEvent.schedule.renderTime = true;
        });
      }
    },
  },
  watch: {
    active_brand: {
      async handler(brand) {
        if (!brand || !brand.id || brand.id !== this.$route.params.brand_id) return;
        this.$store.commit('adminPanel/setLoading', true);

        // load calendar events if they haven't been loaded yet
        if (!this.getEvents().length) {
          try {
            await this.$store.dispatch('calendar/fetchCalendarEvents');
          } catch (err) {
            console.error(err);
          }
        }

        try {
          const eventId = this.$route.params.event_id;
          let event = null;

          if (eventId) {
            event = this.getEvents(CalendarTypes.menu).find((e) => e.data.id === eventId);
          }

          // verify if delivery is enabled
          this.isDeliveryEnabled =
            this.getEvents(CalendarTypes.delivery).length > 0 &&
            this.active_brand &&
            this.active_brand.is &&
            this.active_brand.is.delivery_supported;

          // verify if pickup is enabled
          this.isPickupEnabled =
            this.getEvents(CalendarTypes.business).length > 0 &&
            this.active_brand &&
            this.active_brand.is &&
            this.active_brand.is.pickup_supported;

          this.$store.commit('adminPanel/setViewTitle', {
            depth: 3,
            title: !eventId
              ? 'Schedule Menu'
              : (event && event.data && event.data.title) || 'Untitled',
            to: { name: `scheduler-${eventId ? 'edit' : 'new'}`, params: { event_id: eventId } },
            last: true,
          });

          this.isEventEdit = !!eventId;
          const menus = [];
          if (this.isCentricOSIntegrated(this.active_brand)) {
            const stationMenus = await this.fetchLocalBrandV2Menus(
              this.active_brand.meta.menu.brand,
            );
            menus.push(...stationMenus);
          } else {
            const stationMenus = await this.fetchCompanyMenu({
              id: this.active_brand.company,
              nocache: true,
            }).then((r) =>
              r.menus.filter(
                (m) =>
                  m.location_brand === this.active_brand.id && (this.isEventEdit || m?.is?.linked),
              ),
            );
            menus.push(...stationMenus);
          }
          // When editing we can see disabled\hidden so that we can remove it. When adding - no.
          this.menus.push(
            ...menus
              .filter((m) => this.isEventEdit || (!m.is?.disabled && !m.is?.hidden))
              .map((m) => ({ label: m.label?.en || '', value: m.id })),
          );

          const eventObject = event
            ? this.convertEventToMenuEvent(event)
            : this.getDefaultMenuEvent();

          this.menuEventsBackup = [cloneDeep(eventObject)];
          this.menuEvents.push(cloneDeep(eventObject));

          // set up
          for (let i = 1; i < 52; i += 1) this.weeks.push(i);
        } catch (error) {
          console.error(error);
          this.$toast.error('Couldnt fetch info, please try again');
        }

        this.$store.commit('adminPanel/setLoading', false);
      },
      immediate: true,
    },
  },
};
</script>

<style>
.Remove-Menu-Btn {
  height: 32px;
  font-family: Avenir;
  border-radius: 5px;
  font-size: 22.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #ea526f;
  cursor: pointer;
  padding: 0px 5px 0px 5px;
}

.Remove-Menu-Btn:focus,
.Remove-Menu-Btn:hover {
  background-color: rgba(234, 82, 111, 0.2);
}

.Remove-Icon {
  color: #ea526f;
  margin-bottom: 4px;
}

.Weekday {
  cursor: pointer;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-left: 2px;
  background: #d8d8d8;
}

.SelectedWeekDay {
  background: #0d73d8;
  color: white;
}

.Not-Selected-Error {
  padding: 0 !important;
}

.Not-Selected-Error > .v-input__control > .v-input__slot {
  display: none !important;
}

.nextDayLabel {
  height: 5px;
  margin-top: -25px;
  padding-bottom: 40px;
}

.typeDisabled {
  color: grey;
  margin-left: 30px;
}
</style>
