<template>
  <v-layout align-center class="my-3">
    <div
      v-if="showScheduleOverviewText"
      class="Menu-Schedule-Verbiage pa-3 Body-1-Black-High-Emphasis-Left pb-3 noselect"
    >
      <b>{{ menuSelection }}</b>
      {{ 'is available ' }}
      {{ timeDurationPrefix }}
      <b v-if="timeDuration.length > 0">{{ timeDuration }}</b>
      <b v-if="repeatSelection.length > 0">{{ repeatSelection }}</b>
      {{ dateDurationPrefix }}
      <b v-if="dateDuration.length > 0">{{ dateDuration }}</b>
      {{ serviceTypePrefix }}
      <b v-if="serviceType.length > 0">{{ serviceType }}</b>
    </div>
  </v-layout>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';
import { RecurrenceTypes } from '@/constants';

export default {
  name: 'ScheduleOverviewText',
  props: ['menuEvent', 'menus'],
  data: () => ({
    daysLabel: ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'],
  }),
  computed: {
    menuScheduleOptionsSelected() {
      if (this.menuSelection.length === 0) return 0;
      return [
        this.menuSelection.length > 0,
        this.hasTimeDuration,
        this.hasServiceType,
        this.hasRepeatSelection,
        this.hasDateDuration,
      ].filter((h) => !!h).length;
    },
    showScheduleOverviewText() {
      return this.menuScheduleOptionsSelected > 1;
    },
    menuSelection() {
      try {
        return this.menus.find((m) => m.value === this.menuEvent.menu).label;
      } catch {
        return '';
      }
    },
    timeDurationPrefix() {
      if (!this.hasTimeDuration) return '';
      const { isAllDay } = this.menuEvent.schedule;
      return isAllDay ? '' : 'at ';
    },
    hasTimeDuration() {
      const { startTime, endTime, isAllDay } = this.menuEvent.schedule;
      return (startTime && endTime) || isAllDay;
    },
    timeDuration() {
      const { startTime, endTime, isAllDay } = this.menuEvent.schedule;
      if (!this.hasTimeDuration) return '';
      return isAllDay ? 'all day ' : `${this.formatHour(startTime)}-${this.formatHour(endTime)} `;
    },
    hasRepeatSelection() {
      const { repeat } = this.menuEvent.schedule;
      return !!(
        repeat.type &&
        (repeat.type !== 'Custom' || (Object.keys(repeat.days) > 0 && repeat.frequency > 0))
      );
    },
    repeatSelection() {
      const { days, frequency, type } = this.menuEvent.schedule.repeat;
      if (!this.hasRepeatSelection) return '';
      if (!Object.values(RecurrenceTypes).some((rt) => rt === type)) {
        let prefix = '';
        if (frequency === 1) prefix += 'weekly on';
        else prefix += `every ${frequency} weeks on`;

        const selectedDays = Object.keys(days)
          .filter((d) => !!days[d])
          .map((d) => `${this.daysLabel[d]}`);
        let label = `${prefix} `;
        const numDays = selectedDays.length;
        if (numDays > 1) {
          selectedDays.forEach((d, i) => {
            if (i < numDays - 1) label += `${d}, `;
            else label += `${d}`;
          });
        } else label += `${selectedDays.join('')}`;
        return label;
      }
      if (type !== RecurrenceTypes.NeverRepeat) {
        switch (type) {
          case RecurrenceTypes.Daily:
            return 'daily ';
          case RecurrenceTypes.Weekday:
            return 'every weekday ';
          default:
            return '';
        }
      } else return '';
    },
    hasDateDuration() {
      const { startDate } = this.menuEvent.schedule;
      return !!startDate;
    },
    dateDurationPrefix() {
      if (!this.hasDateDuration) return '';
      const { repeat } = this.menuEvent.schedule;
      if (repeat.type === RecurrenceTypes.NeverRepeat) {
        return 'on ';
      }
      return 'from ';
    },
    dateDuration() {
      if (!this.hasDateDuration) return '';
      const { startDate, endDate, hasEndDate } = this.menuEvent.schedule;
      return `${this.formatLocaleDate(startDate)}${hasEndDate && !!endDate ? '-' : ''}${
        hasEndDate && endDate ? this.formatLocaleDate(endDate) : ''
      }`;
    },
    hasServiceType() {
      const { menuEvent } = this;
      return menuEvent.is && (menuEvent.is.pickup || menuEvent.is.delivery);
    },
    serviceTypePrefix() {
      return this.hasServiceType ? 'for ' : '';
    },
    serviceType() {
      const { menuEvent } = this;
      return this.hasServiceType
        ? `${menuEvent.is.pickup ? 'Pickup ' : ''}${
            menuEvent.is.pickup && menuEvent.is.delivery ? 'and ' : ''
          }${menuEvent.is.delivery ? 'Delivery' : ''}`
        : '';
    },
  },
  methods: {
    formatLocaleDate(date) {
      return !date ? date : DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },
    formatHour(time) {
      if (!time) return '';

      const times = time.split(':');
      let hours = Number(times[0]);
      let minutes = Number(times[1]);
      const ampm = hours % 24 < 12 ? 'am' : 'pm';

      if (minutes < 10) {
        minutes = `0${minutes}`; // adding leading zero
      }

      hours %= 12;
      if (hours === 0) {
        hours = 12;
      }

      return `${hours}:${minutes}${ampm}`;
    },
  },
};
</script>

<style>
.Menu-Schedule-Verbiage {
  background: #fff8de;
  width: 100%;
  min-height: 58px;
}
</style>
