import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(VFlex, { attrs: { xs8: "" } }, [_c("view-title")], 1),
          _c(
            VFlex,
            { attrs: { xs4: "" } },
            [
              _vm.isEventEdit && _vm.canWriteMenuSchedule
                ? _c(VLayout, { attrs: { "justify-end": "" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "Remove-Menu-Btn mt-4 noselect",
                        on: {
                          click: function($event) {
                            return _vm.deleteEvent()
                          }
                        }
                      },
                      [
                        _c(
                          VIcon,
                          {
                            staticClass: "Remove-Icon",
                            attrs: { color: "#ea526f" }
                          },
                          [_vm._v("mdi-delete-outline")]
                        ),
                        _vm._v("Delete Menu Set\n        ")
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        VLayout,
        { staticClass: "mt-1 mb-4 H6-Selected-On-Surface-High-Emphasis-Left" },
        [_vm._v("\n    The following will serve as your mobile menus\n  ")]
      ),
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VForm,
            {
              ref: "form",
              staticStyle: { width: "100%" },
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.isFormValid,
                callback: function($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            _vm._l(_vm.menuEvents, function(menuEvent, index) {
              return _c(
                VCard,
                {
                  key: index,
                  staticClass: "v-card-custom mb-5",
                  attrs: { width: "100%" }
                },
                [
                  _c(
                    VLayout,
                    [
                      _c(VFlex, [
                        _c("div", { staticClass: "H5-Primary-Left" }, [
                          _vm._v("Menu Details")
                        ])
                      ]),
                      _vm.canWriteMenuSchedule && _vm.menuEvents.length > 1
                        ? _c(
                            VFlex,
                            { attrs: { xs4: "" } },
                            [
                              _c(VLayout, { attrs: { "justify-end": "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "Remove-Menu-Btn mt-4 noselect",
                                    on: {
                                      click: function($event) {
                                        return _vm.removeEvent(menuEvent, index)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      VIcon,
                                      {
                                        staticClass: "Remove-Icon",
                                        attrs: { color: "#ea526f" }
                                      },
                                      [_vm._v("mdi-delete-outline")]
                                    ),
                                    _vm._v(
                                      "Delete Menu\n                Set\n              "
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        VLayout,
                        { staticClass: "mt-5" },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs6: "" } },
                            [
                              _c(VAutocomplete, {
                                attrs: {
                                  items: _vm.menus,
                                  label: "Menu",
                                  placeholder: "",
                                  rules: _vm.requiredMenu,
                                  disabled:
                                    !_vm.canWriteMenuSchedule ||
                                    _vm.isEventEdit,
                                  "item-text": "label",
                                  "item-value": "value"
                                },
                                model: {
                                  value: menuEvent.menu,
                                  callback: function($$v) {
                                    _vm.$set(menuEvent, "menu", $$v)
                                  },
                                  expression: "menuEvent.menu"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        {
                          staticClass: "mt-5 H6-Secondary-Left",
                          staticStyle: { width: "240px" }
                        },
                        [_vm._v(" Menu Duration ")]
                      ),
                      _c(
                        VLayout,
                        [
                          _c(
                            VFlex,
                            [
                              _c(
                                VLayout,
                                [
                                  _c(VCheckbox, {
                                    attrs: {
                                      "hide-details": "",
                                      color: "#4020ae",
                                      "false-value": false,
                                      "true-value": true,
                                      label: "All day",
                                      disabled: !_vm.canWriteMenuSchedule
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.toggleAllDay(menuEvent)
                                      }
                                    },
                                    model: {
                                      value: menuEvent.schedule.isAllDay,
                                      callback: function($$v) {
                                        _vm.$set(
                                          menuEvent.schedule,
                                          "isAllDay",
                                          $$v
                                        )
                                      },
                                      expression: "menuEvent.schedule.isAllDay"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "Not-Selected-Error",
                                        attrs: {
                                          rules: _vm.timeRules(menuEvent),
                                          value:
                                            menuEvent.schedule.startTime +
                                            "-" +
                                            menuEvent.schedule.endTime,
                                          disabled: !_vm.canWriteMenuSchedule
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    VFlex,
                                    { staticClass: "pr-2", attrs: { xs3: "" } },
                                    [
                                      menuEvent.schedule.renderTime
                                        ? _c("time-select", {
                                            attrs: {
                                              label: "Start Time",
                                              value:
                                                menuEvent.schedule.startTime,
                                              rules: _vm.timeOpenRules(
                                                menuEvent.schedule.isAllDay
                                              ),
                                              autocomplete: "",
                                              disabled:
                                                menuEvent.schedule.isAllDay ||
                                                !_vm.canWriteMenuSchedule,
                                              type: menuEvent.schedule.isAllDay
                                                ? "text"
                                                : "time"
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.$set(
                                                  menuEvent.schedule,
                                                  "startTime",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    { attrs: { xs3: "" } },
                                    [
                                      menuEvent.schedule.renderTime
                                        ? _c("time-select", {
                                            attrs: {
                                              label:
                                                menuEvent.closeTimeLabel ||
                                                "End Time",
                                              value: menuEvent.schedule.endTime,
                                              rules: _vm.timeClosedRules(
                                                menuEvent.schedule.isAllDay
                                              ),
                                              autocomplete: "",
                                              disabled:
                                                menuEvent.schedule.isAllDay ||
                                                !_vm.canWriteMenuSchedule,
                                              type: menuEvent.schedule.isAllDay
                                                ? "text"
                                                : "time"
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.$set(
                                                  menuEvent.schedule,
                                                  "endTime",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "nextDayLabel" },
                                        [_vm._v(_vm._s(menuEvent.nextDayLabel))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs3: "" } },
                                    [
                                      menuEvent.schedule.renderRecurrence
                                        ? _c(VCombobox, {
                                            attrs: {
                                              items: _vm.repeatOptions,
                                              label: "Repeat",
                                              value:
                                                menuEvent.schedule.repeat.type,
                                              rules: _vm.requiredField.concat([
                                                _vm.isValidRecurrence(menuEvent)
                                              ]),
                                              disabled: !_vm.canWriteMenuSchedule
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.setRepeatType(
                                                  $event,
                                                  menuEvent
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                { staticClass: "mt-3" },
                                [
                                  menuEvent.schedule.repeat.type !==
                                  _vm.RecurrenceTypes.NeverRepeat
                                    ? _c(VCheckbox, {
                                        attrs: {
                                          "hide-details": "",
                                          color: "#4020ae",
                                          "false-value": true,
                                          "true-value": false,
                                          label: "No end date",
                                          disabled: !_vm.canWriteMenuSchedule
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        },
                                        model: {
                                          value: menuEvent.schedule.hasEndDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              menuEvent.schedule,
                                              "hasEndDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "menuEvent.schedule.hasEndDate"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    VFlex,
                                    { staticClass: "mr-1", attrs: { xs4: "" } },
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          ref: "startDate",
                                          refInFor: true,
                                          attrs: {
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            "z-index": "2",
                                            lazy: "",
                                            transition: "scale-transition",
                                            "full-width": "",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g({}, on),
                                                      [
                                                        _c(VTextField, {
                                                          staticClass:
                                                            "DateStyle border-label-input",
                                                          attrs: {
                                                            value: _vm.formatLocaleDate(
                                                              menuEvent.schedule
                                                                .startDate
                                                            ),
                                                            readonly: "",
                                                            "persistent-hint":
                                                              "",
                                                            outline: "",
                                                            label: "Start Date",
                                                            rules:
                                                              _vm.requiredField,
                                                            "append-icon":
                                                              "mdi-calendar-blank",
                                                            disabled: !_vm.canWriteMenuSchedule
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              menuEvent.schedule.showStartDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                menuEvent.schedule,
                                                "showStartDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "menuEvent.schedule.showStartDate"
                                          }
                                        },
                                        [
                                          _c(VDatePicker, {
                                            attrs: {
                                              "no-title": "",
                                              min: _vm.today,
                                              disabled: !_vm.canWriteMenuSchedule
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.onDateChange(
                                                  menuEvent.schedule
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                menuEvent.schedule.startDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  menuEvent.schedule,
                                                  "startDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "menuEvent.schedule.startDate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  menuEvent.schedule.hasEndDate
                                    ? _c(
                                        VFlex,
                                        { attrs: { xs4: "" } },
                                        [
                                          _c(
                                            VMenu,
                                            {
                                              ref: "endDate",
                                              refInFor: true,
                                              attrs: {
                                                "close-on-content-click": false,
                                                "nudge-right": 40,
                                                lazy: "",
                                                "z-index": "2",
                                                transition: "scale-transition",
                                                "full-width": "",
                                                "min-width": "290px"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c(VTextField, {
                                                              staticClass:
                                                                "DateStyle border-label-input",
                                                              attrs: {
                                                                value: _vm.formatLocaleDate(
                                                                  menuEvent
                                                                    .schedule
                                                                    .endDate
                                                                ),
                                                                readonly: "",
                                                                "persistent-hint":
                                                                  "",
                                                                outline: "",
                                                                label:
                                                                  "End Date",
                                                                rules:
                                                                  _vm.requiredField,
                                                                "append-icon":
                                                                  "mdi-calendar-blank",
                                                                disabled: !_vm.canWriteMenuSchedule
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  menuEvent.schedule
                                                    .showEndDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    menuEvent.schedule,
                                                    "showEndDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "menuEvent.schedule.showEndDate"
                                              }
                                            },
                                            [
                                              _c(VDatePicker, {
                                                attrs: {
                                                  "no-title": "",
                                                  min: _vm.getMinCloseDate(
                                                    menuEvent.schedule.startDate
                                                  ),
                                                  disabled: !_vm.canWriteMenuSchedule
                                                },
                                                on: {
                                                  input: function($event) {
                                                    menuEvent.schedule.showEndDate = false
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    menuEvent.schedule.endDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      menuEvent.schedule,
                                                      "endDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "menuEvent.schedule.endDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                {
                                  staticClass: "mt-5 H6-Secondary-Left",
                                  staticStyle: { width: "240px" }
                                },
                                [
                                  _vm._v(
                                    "\n                Service Types\n              "
                                  )
                                ]
                              ),
                              _c(
                                VLayout,
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "Not-Selected-Error",
                                        attrs: {
                                          rules: _vm.checkboxRules(
                                            menuEvent.is.pickup ||
                                              menuEvent.is.delivery
                                          ),
                                          value:
                                            (menuEvent.is.delivery ? 1 : 0) +
                                            (menuEvent.is.pickup ? 1 : 0),
                                          disabled: !_vm.canWriteMenuSchedule
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                {
                                  staticClass: "mb-5",
                                  staticStyle: { width: "300px" }
                                },
                                [
                                  _c(
                                    VFlex,
                                    [
                                      _c(VCheckbox, {
                                        attrs: {
                                          "hide-details": "",
                                          color: "#4020ae",
                                          "false-value": false,
                                          "true-value": true,
                                          label: "Pickup",
                                          disabled:
                                            !_vm.isDeliveryEnabled ||
                                            !_vm.isPickupEnabled ||
                                            !_vm.canWriteMenuSchedule
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        },
                                        model: {
                                          value: menuEvent.is.pickup,
                                          callback: function($$v) {
                                            _vm.$set(
                                              menuEvent.is,
                                              "pickup",
                                              $$v
                                            )
                                          },
                                          expression: "menuEvent.is.pickup"
                                        }
                                      }),
                                      !_vm.isPickupEnabled
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "noselect typeDisabled"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    (Not available at this brand)\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    [
                                      _c(VCheckbox, {
                                        attrs: {
                                          "hide-details": "",
                                          color: "#4020ae",
                                          "false-value": false,
                                          "true-value": true,
                                          disabled:
                                            !_vm.isDeliveryEnabled ||
                                            !_vm.isPickupEnabled ||
                                            !_vm.canWriteMenuSchedule,
                                          label: "Delivery"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        },
                                        model: {
                                          value: menuEvent.is.delivery,
                                          callback: function($$v) {
                                            _vm.$set(
                                              menuEvent.is,
                                              "delivery",
                                              $$v
                                            )
                                          },
                                          expression: "menuEvent.is.delivery"
                                        }
                                      }),
                                      !_vm.isDeliveryEnabled
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "noselect typeDisabled"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    (Not available at this brand)\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("sheduleOverviewText", {
                                attrs: {
                                  menuEvent: menuEvent,
                                  menus: _vm.menus
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "", width: "580px", height: "428px" },
          model: {
            value: _vm.dialog.isOpen,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "isOpen", $$v)
            },
            expression: "dialog.isOpen"
          }
        },
        [
          _vm.dialog.isOpen
            ? _c(
                VCard,
                { staticClass: "pa-4" },
                [
                  _c(
                    VForm,
                    {
                      ref: "recurrenceForm",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.isRecurrenceValid,
                        callback: function($$v) {
                          _vm.isRecurrenceValid = $$v
                        },
                        expression: "isRecurrenceValid"
                      }
                    },
                    [
                      _c(
                        VCardTitle,
                        { staticClass: "H4-Secondary-Center" },
                        [_vm._v("Custom Recurrence")]
                      ),
                      _c(
                        VLayout,
                        {
                          staticClass:
                            "mx-4 my-4 H6-Selected-On-Surface-High-Emphasis-Left",
                          staticStyle: { width: "330px" }
                        },
                        [
                          _c(VFlex, [_vm._v(" Repeat every ")]),
                          _c(
                            VFlex,
                            [
                              _c(
                                VLayout,
                                {
                                  staticStyle: {
                                    width: "60px",
                                    "margin-top": "-15px"
                                  }
                                },
                                [
                                  _c(VSelect, {
                                    attrs: {
                                      items: _vm.weeks,
                                      rules: _vm.requiredField
                                    },
                                    on: {
                                      keydown: function($event) {
                                        $event.keyCode === 13
                                          ? $event.preventDefault()
                                          : false
                                      }
                                    },
                                    model: {
                                      value: _vm.dialog.data.repeat,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dialog.data, "repeat", $$v)
                                      },
                                      expression: "dialog.data.repeat"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(VFlex, [_vm._v(" Week(s) ")])
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticClass: "mx-4" },
                        [
                          _c(
                            VFlex,
                            [
                              _c(
                                VLayout,
                                {
                                  staticClass:
                                    "mt-4 H6-Selected-On-Surface-High-Emphasis-Left"
                                },
                                [
                                  _vm._v(
                                    "\n              Repeat on\n            "
                                  )
                                ]
                              ),
                              _c(
                                VLayout,
                                {
                                  staticClass: "my-4",
                                  staticStyle: { width: "250px" },
                                  attrs: { "justify-space-between": "" }
                                },
                                _vm._l(
                                  ["S", "M", "T", "W", "T", "F", "S"],
                                  function(day, dIndex) {
                                    return _c(
                                      VFlex,
                                      {
                                        key: dIndex,
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleDay(
                                              _vm.dialog.data.days,
                                              dIndex
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          VLayout,
                                          {
                                            staticClass: "Weekday noselect",
                                            class: {
                                              SelectedWeekDay:
                                                _vm.dialog.data.days[dIndex]
                                            },
                                            attrs: {
                                              "align-center": "",
                                              "justify-center": ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(day) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _c(
                            VBtn,
                            {
                              attrs: { flat: "", color: "blue" },
                              on: {
                                click: function($event) {
                                  return _vm.cancelRecurrence()
                                }
                              }
                            },
                            [_vm._v("CANCEL")]
                          ),
                          _c(
                            VBtn,
                            {
                              attrs: {
                                flat: "",
                                color: "blue",
                                disabled:
                                  !_vm.isRecurrenceValid ||
                                  _vm.validDaysCount(_vm.dialog.data.days) ===
                                    0 ||
                                  !_vm.canWriteMenuSchedule
                              },
                              on: {
                                click: function($event) {
                                  return _vm.confirmRecurrence()
                                }
                              }
                            },
                            [_vm._v("CONTINUE")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        VLayout,
        { attrs: { "justify-center": "" } },
        [
          !_vm.isEventEdit && _vm.canWriteMenuSchedule
            ? _c(
                VFlex,
                {
                  staticClass: "Subtitle-1-Primary-Left noselect",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function($event) {
                      _vm.menuEvents.push(_vm.getDefaultMenuEvent())
                    }
                  }
                },
                [
                  _c(
                    VIcon,
                    { staticClass: "mr-1", attrs: { color: "#0d73d8" } },
                    [_vm._v("mdi-plus")]
                  ),
                  _vm._v("Schedule another Menu\n    ")
                ],
                1
              )
            : _vm._e(),
          _vm.isFormDirty && _vm.canWriteMenuSchedule
            ? _c(
                VFlex,
                [
                  _c(
                    VLayout,
                    { attrs: { flex: "", column: "", "align-end": "" } },
                    [
                      _c(
                        VFlex,
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                flat: "",
                                color: "rounded m-2",
                                disabled: _vm.isSaving
                              },
                              on: {
                                click: function($event) {
                                  return _vm.cancel()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            VBtn,
                            {
                              attrs: {
                                color: "primary rounded m-2",
                                disabled: !_vm.isFormValid || _vm.isSaving
                              },
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [
                              _c(VIcon, { staticClass: "mr-3" }, [
                                _vm._v("mdi-content-save")
                              ]),
                              _vm._v("\n            Save\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }